import React from "react";
import { Slide } from "react-reveal";
import nur from "../../assets/images/icons/children-kids-icon.svg";
import pri from "../../assets/images/icons/childrens-kids-icon.svg";
import tot from "../../assets/images/content/count_2.png";

const Enrollment = () => {
  return (
    <section className="enrollment section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="enroll-container flex">
                <Slide left>
                  <div className="enrol-item">
                    <div className="enrol-icon">
                      <img src={nur} alt="Kids" />
                    </div>
                    <div className="enrol-figure">
                      <h4>162 Pupils</h4>
                    </div>
                    <p className="enrol-text">Nursery</p>
                  </div>
                </Slide>
                <Slide top>
                  <div className="enrol-item">
                    <div className="enrol-icon">
                      <img src={pri} alt="Kids" />
                    </div>
                    <div className="enrol-figure">
                      <h4>448 Pupils</h4>
                    </div>
                    <p className="enrol-text">Primary</p>
                  </div>
                </Slide>
                <Slide right>
                  <div className="enrol-item">
                    <div className="enrol-icon tot">
                      <img src={tot} alt="Kids" />
                    </div>
                    <div className="enrol-figure">
                      <h4>610 Pupils</h4>
                    </div>
                    <p className="enrol-text">Total</p>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Enrollment;
