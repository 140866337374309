import React from "react";
import Slider from "react-slick";
import { Slide, Fade } from "react-reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settingsrecent } from "../reusables/carouselrecent";
import s1 from "../../assets/images/2025/1.jpg";
import s2 from "../../assets/images/2025/2.jpg";
import s3 from "../../assets/images/2025/3.jpg";
import s4 from "../../assets/images/2025/4.jpg";
import s5 from "../../assets/images/2025/5.jpg"; 
import s6 from "../../assets/images/staffparty2024/6.jpg";
import s7 from "../../assets/images/staffparty2024/7.jpg";
import s8 from "../../assets/images/staffparty2024/8.jpg";
import s9 from "../../assets/images/staffparty2024/9.jpg";
import s10 from "../../assets/images/staffparty2024/10.jpg";
import s11 from "../../assets/images/2025/6.jpg";
import s12 from "../../assets/images/2025/7.jpg";
import s13 from "../../assets/images/2025/8.jpg";
import s14 from "../../assets/images/2025/9.jpg";
import s15 from "../../assets/images/2025/10.jpg";
import s16 from "../../assets/images/2025/11.jpg";
import s17 from "../../assets/images/2025/12.jpg";
import s18 from "../../assets/images/2025/13.jpg";
import s19 from "../../assets/images/2025/14.jpg";
import GalleryCard from "../reusables/GalleryCard";

const RecentEvents = () => {
  return (
    <section className="sections section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide top>
                <div className="section-head center">
                  <h3 className="text-shadow green">Recent Events in Photos</h3>
                  <p className=" margin-top-20 width-600 margin-auto section-spacing-20">
                    As it happened...
                  </p>
                </div>
              </Slide>
              <div className="section-body section-spacing-50 no-padding-top">
                <div className="slider-items-container gallery-container">
                  <Slider {...settingsrecent}>
                    <GalleryCard
                      galleryImage={s1}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s2}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s3}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s4}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s5}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s11}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s12}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s13}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s14}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s15}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s16}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s17}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s18}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s19}
                      galleryImageCaption="KCG New Look 2025"
                    />
                    <GalleryCard
                      galleryImage={s6}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s7}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s8}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s9}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                    <GalleryCard
                      galleryImage={s10}
                      galleryImageCaption="End of Year Staff Party 2024"
                    />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecentEvents;
